import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/images/common/dialog_title.png'


const _hoisted_1 = {
  key: 1,
  class: "dialog-welcome-container"
}
const _hoisted_2 = { class: "ad-wrap" }

import { ref } from 'vue';
import { Haa } from '@haa/fwk-h5/lib/index';
import bus from '@haa/fwk-h5/lib/bus';

export default /*@__PURE__*/_defineComponent({
  __name: 'WelcomeDialog',
  setup(__props) {

const { strategy } = Haa.util().useStrategy();
/** 展示类型，-1：不展示，0：官方插屏，1：对话框插屏 */
const showType = ref<number>(-1);
const setShowType = () => {
    if (strategy.h5_ad_space_config.Home_dialog) {
        showType.value = 1;
    } else if (strategy.h5_ad_space_config.Home_inter) {
        showType.value = 0;
    }
}
if (strategy.status != 0) {
    setShowType();
} else {
    bus.on('strategy', setShowType);
}

const handleClose = () => {
    showType.value = -1;
}

return (_ctx: any,_cache: any) => {
  const _component_wap_inter = _resolveComponent("wap-inter")!
  const _component_wap_native = _resolveComponent("wap-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: "dialog-welcome-wrap",
    style: _normalizeStyle({'width': showType.value == 1 ? '100%': '0', 'height': showType.value == 1 ? '100%' : '0'})
  }, [
    (showType.value == 0)
      ? (_openBlock(), _createBlock(_component_wap_inter, {
          key: 0,
          "space-key": "Home_inter"
        }))
      : _createCommentVNode("", true),
    (showType.value == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "dialog-info" }, [
            _createElementVNode("img", {
              class: "title",
              src: _imports_0
            }),
            _createElementVNode("div", { class: "desc" }, "hi萌友们～宠物汇拥有丰富的宠物知识库，在这里你可以了解到各式各样的宠物信息，还可以帮您解决在养宠的时候遇到的各种问题，同时还拥有精美的宠物图片和视频让您可以在这里畅快的云吸宠，快进来体验吧～")
          ], -1)),
          _createElementVNode("div", {
            class: "click-btn",
            onClick: handleClose
          }, "知道了"),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_wap_native, { "space-key": "Home_dialog" })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}
}

})