<script setup lang="ts">
import MainTitle from '@/components/MainTitle.vue';
import MainTab from '@/components/MainTab.vue';
import WelcomeDialog from '@/components/dialog/WelcomeDialog.vue';
import IcpComp from '@/components/IcpComp.vue';

import { Haa } from '@haa/fwk-h5/lib/index';
Haa.util().useLifeCycle();

import { ref, onMounted } from 'vue';
import { useAdControl } from '@/hooks/useAdControl';
const { HOME_INTER_SHOWN, HOME_BANNER_SHOW  } = useAdControl();
const showInter = ref<boolean>(false);
if (!HOME_INTER_SHOWN.value) {
    showInter.value = true;
    HOME_INTER_SHOWN.value = true;
}
onMounted(() => {
    HOME_BANNER_SHOW.value = true;
})
const handleBannerDispear = () => {
    HOME_BANNER_SHOW.value = false;
}
</script>

<template>
    <div class="home-view-wrapper">
        <div class="main-bg"></div>
        <div class="home-view-container">
            <main-title></main-title>

            <div class="page-box" v-slide>
                <main-tab></main-tab>
                <router-view />
                <icp-comp></icp-comp>
                <div style="width: 100%; height: 120px;"></div>
                
            </div>
        </div>

        <welcome-dialog v-if="showInter"></welcome-dialog>

        <wap-banner space-key="Home_banner" @aderror="handleBannerDispear" @adclose="handleBannerDispear"></wap-banner>
    </div>
</template>

<style scoped lang="less">
.home-view-wrapper {
    height: 100vh;
    background-color: #F1F0F5;
    position: relative;
    .main-bg {
        width: 100%;
        height: 420px;
        position: absolute;
        left: 0;top: 0;
        background: linear-gradient(180deg, #BE9FFF 10.74%, rgba(255, 255, 255, 0) 99.33%);
        z-index: 1;
    }
    .home-view-container {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        .page-box {
            flex: 1;
            overflow-y: auto;
        }
    }
}
</style>
