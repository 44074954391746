import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "main-tab-wrap" }
const _hoisted_2 = { class: "main-tab-box" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

import router from '@/router';
import { ref, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';

export default /*@__PURE__*/_defineComponent({
  __name: 'MainTab',
  setup(__props) {

const { oeReport } = Haa.util().useOeReport();

const route = useRoute();
const active = ref<number>(0);
const tabList = reactive([
    { icon: require('@/assets/images/tabbar/tab_1.png'), icon_s: require('@/assets/images/tabbar/tab_1_s.png'), text: '养成必修课', path: '/main' },
    { icon: require('@/assets/images/tabbar/tab_2.png'), icon_s: require('@/assets/images/tabbar/tab_2_s.png'), text: '宠物小课堂', path: '/classroom' },
    { icon: require('@/assets/images/tabbar/tab_3.png'), icon_s: require('@/assets/images/tabbar/tab_3_s.png'), text: '宠物百科', path: '/wikipedia' },
    { icon: require('@/assets/images/tabbar/tab_4.png'), icon_s: require('@/assets/images/tabbar/tab_4_s.png'), text: '宠星座', path: '/constellation' },
])
tabList.forEach((item, index) => {
    if (item.path == route.path) active.value = index;
})
watch(
    () => route.path,
    () => {
        tabList.forEach((item, index) => {
            if (item.path == route.path) active.value = index;
        })
    }
);

const handleClick = (index: number) => {
    active.value = index;
    let tab = tabList[active.value];
    oeReport(OeReportType.Action, OeReportSubType.TabClick, tabList[active.value].text);
    router.replace({ path: tab.path });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "tab-item",
          onClick: ($event: any) => (handleClick(index))
        }, [
          _createElementVNode("img", {
            src: active.value == index ? item.icon_s : item.icon
          }, null, 8, _hoisted_4)
        ], 8, _hoisted_3))
      }), 256))
    ])
  ]))
}
}

})